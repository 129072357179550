<template>
  <div />
</template>

<script>
export default {
  created() {
    this.logout()
  },
  methods: {
    async logout() {
      // clear state
      this.$store.dispatch('setCourierVisibility', false)
      this.$store.dispatch('setCourierLogState', false)
      this.$store.dispatch('setCourierCar', null)

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem(this.$JWT.config.storageTokenKeyName)
      localStorage.removeItem(this.$JWT.config.storageRefreshTokenKeyName)

      this.$router.push({ name: 'login' })
    },
  },
}
</script>